<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('exam_schedule_available_time')" :is-filter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('exam_schedule_available_time')" :is-filter="false"/>
            </template>
            <!-- Datatable -->
            <datatable-filter
                v-show="datatable.filterStatus"
                @filter="filter"
                @filterClear="filterClear"
                :export-excel="checkPermission('examschedule_excelexport')"
                @exportExcel="downloadExcel">
                <ValidationObserver ref="examScheduleAvaibleTime">
                    <b-row>
                        <b-col cols="12" md="6" lg="4" xl="3">
                            <ValidationProvider name="semester_id" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('academic_year')">
                                    <semesters-selectbox
                                        ref="semester_id"
                                        v-model="form.semester_id" :validate-error="errors[0]">
                                    </semesters-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6" lg="4" xl="3">
                            <ValidationProvider name="course_id" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('course')">
                                    <course-auto-complete ref="course_id"  v-model="form.course_id" :state="errors[0] ? false : null"/>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6" lg="4" xl="3">
                            <ValidationProvider name="start_date" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('start_date')">
                                    <b-form-input type="date" v-model="form.start_date"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6" lg="4" xl="3">
                            <ValidationProvider name="end_date" rules="required" v-slot="{valid, errors}">
                                <b-form-group :label="$t('end_date')">
                                    <b-form-input type="date" v-model="form.end_date"></b-form-input>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6" lg="4" xl="3">
                            <b-form-group :label="$t('exam_type')">
                                <parameter-selectbox ref="type" code="exam_schedules_types" v-model="form.type"></parameter-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4" xl="3">
                            <ValidationProvider name="exam_time" rules="required|numeric" v-slot="{valid, errors}">
                                <b-form-group :label="$t('exam_time')">
                                    <b-form-input v-model="form.exam_time" type="number"
                                    :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6" lg="4" xl="3">
                            <ValidationProvider name="section" rules="" v-slot="{valid, errors}">
                                <b-form-group :label="$t('section')">
                                    <b-form-input v-model="form.section" type="number"
                                    :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                                <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" class="mb-2"/>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <datatable
                :isLoading="datatable.isLoading"
                :columns="datatable.columns"
                :rows="datatable.rows"
                :total="datatable.total"
                v-show="datatable.showTable"
                :resultTitle="false"
            >
            </datatable>
        </app-layout>
    </div>
</template>
<script>
import ExamScheduleService from "@/services/ExamScheduleService";

export default {
    name: "ExamScheduleFindSuitableTime",
    metaInfo() {
        return {
            title: this.$t("exam_schedule_available_time"),
        };
    },
    data() {
        return {
            form: {},
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: this.toUpperCase("semester_1"),
                        field: "semester_id",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("exam_type"),
                        field: "type",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("course_code"),
                        field: "course_id",
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("section"),
                        field: "section",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("date"),
                        field: "date",
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.toUpperCase("time_between"),
                        field: "time",
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
            },
            filterData: {
                semester_id: null,
                type: null,
                course_id: null,
                section: null,
            },
        };
    },
    methods: {

        async getRows() {
            const isValid = await this.$refs.examScheduleAvaibleTime.validate();
            if(!isValid) {
                return
            }
            this.datatable.showTable = true;
            this.datatable.isLoading = true;
            const formData = new FormData();
            Object.keys(this.form).forEach(key => formData.append(key, this.form[key]))
            return ExamScheduleService.examSchedulesFindTime(formData)
                                        .then((response) => {
                                            this.setFilterData()
                                            const data = response.data.data.map(
                                                item => ({
                                                    date: item.date,
                                                    time: `${item.from}-${item.to}`,
                                                    semester_id: this.filterData["semester_id"],
                                                    type: this.filterData.type,
                                                    course_id: this.filterData["course_id"],
                                                    section: this.filterData.section
                                                })
                                            );
                                            this.datatable.rows = data
                                        }).catch(err=>this.showErrors(err))
                                        .finally(() => {
                                            this.datatable.isLoading = false;
                                        });
        },
        downloadExcel() {
           const headers = [
                { header: this.$t('semester_1'), key: "semester_id", width: 20 },
                { header: this.$t('exam_type'), key: "type", width: 20 },
                { header: `${this.$t('course_code')}`, key: "course_id", width: 23 },
                { header: `${this.$t('section')}`, key: "section", width: 15 },
                { header: this.$t('date'), key: "date", width: 20 },
                { header: this.$t('time_between'), key: "time", width: 20}
            ]
            this._downloadExcelFile({ data: this.datatable.rows, filename : `${this.$t('exam_schedule_available_time')}.xlsx`, headers})
        },
        setFilterData(){
            this.filterData = {}
            const fields = ["semester_id", "type", "course_id"]
            fields.forEach(item => {
                if(this.$refs[item].selected) {
                    this.filterData[item] = this.$refs[item].selected[0].text
                }
            })
            if(this.form.section) {
                this.filterData.section = this.form.section
            }
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.form = {}
        },
    },
};
</script>
